.card-style:hover {
    opacity: 0.9;
    cursor: pointer
}

@media (min-width:990px) {
    .card-info{
        display: none;
    }
}

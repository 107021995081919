.about{
    padding: 20px 20px 20px 20px;
}

@media (max-width:990px) {
    .about{
        margin-top: auto;
    }
    .about{
        margin-top: 150px;
    }
    .blog{
        margin-top: 150px;
    }
    .exibitions{
        margin-top: 150px;
    }
    #section1{
        margin-top: 100px;
    }
}

@media (min-width:990px) {
    #section1{
        margin-top: 20px;
    }
}
